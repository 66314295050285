import React, { useMemo, useState, useEffect } from "react";

import { useDealerManagementContext } from "../context";

import { useCompanyInformation } from "@api/companies";

import { makeStyles } from "@material-ui/core/styles";

import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button } from "~/components/Base/BaseActions";

import { getErrorMessage } from "@utils/errors";

import { emailValidator } from "@autocorp/web-core-utils/lib/emailValidator";
import { usePreviousState } from "@utils/state";

import { Loading } from "../Loading";
import type { ICompanyFormProps, ICompanyState } from "@admin/types";
import { useFeedbackContext } from "~/components/Feedback";


const useStyles = makeStyles(() => ({
    root: {
        padding: 5,
        boxShadow: "none",
        width: 375,
    },
    button: {
        transition: "none",
    },
}));

type CompanyStateHandlerKeys = keyof ICompanyState;
type CompanyStateHandlers = Record<CompanyStateHandlerKeys, (val: string) => void>;

export const CompanyForm: React.FC<ICompanyFormProps> = ( { onSubmit, onComplete, buttonLabel, variables, formType }) => {
    const { companyId } = useDealerManagementContext();
    const { handleOpenFeedback } = useFeedbackContext();
    const { company } = useCompanyInformation(companyId!);
    const styles = useStyles();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [inputValues, updateValue] = useState<ICompanyState>({
        companyName: "",
        companyAdminEmail: "",
        zohoId: "",
    });

    const previousCompany = usePreviousState(company?.id);

    useEffect(() => {
        if(previousCompany !== company?.id && formType !== "create") {
            updateValue({
                companyName: formType === "clone" ? `Clone ${company?.name}` : company?.name || "",
                companyAdminEmail: "",
                zohoId: company?.zohoId || "",
            });
        }
    }, [company?.id, company?.name, company?.zohoId, formType, previousCompany]);

    const inputHandler = useMemo<CompanyStateHandlers>(() => {
        const scopedHandler = (key: CompanyStateHandlerKeys) => (val: string) => {
            updateValue((state) => ({
                ...state,
                [key]: val,
            }));
        };
        return Object.keys(inputValues).reduce((acc, key) => ({
            ...acc,
            [key as CompanyStateHandlerKeys]: scopedHandler(key as CompanyStateHandlerKeys),
        }), {} as CompanyStateHandlers);
    }, [updateValue, inputValues]);

    const formValues = {
        ...inputValues,
        ...variables,
    };

    const handleSubmit = async () => {
        let complete = false;
        if (!loading) {
            if (formType !== "update" && !emailValidator.test(inputValues.companyAdminEmail)) {
                return setError("Please enter a valid Admin email address");
            }
            if (!inputValues.zohoId) {
                return setError("Zoho ID is mandatory");
            }
            if (!inputValues.companyName) {
                return setError("Company name is mandatory");
            }
            setLoading(true);
            try {
                inputValues && await onSubmit(formValues);
                complete = true;
                handleOpenFeedback({message: "Your changes has been saved!", severity: "success"});
                onComplete();
            } catch (error) {
                setError(getErrorMessage(error));
            } finally {
                if (!complete) setLoading(false);
            }
        }
    };

    if (!company && formType !== "create") {
        return <Loading />;
    }

    return (
        <BaseForm error={error} className={styles.root}>
            <Input
                label="Company Name"
                placeholder="Dealer Name"
                update={inputHandler.companyName}
                id={"input-company-name"}
                value={inputValues.companyName}

            />
            {formType !== "update" &&
            <Input
                label="Company Admin Email"
                placeholder="Company Admin Email"
                update={inputHandler.companyAdminEmail}
                id={"input-admin-email"}
            />}
            <Input
                label="Zoho ID"
                placeholder="Zoho ID"
                update={inputHandler.zohoId}
                id={"input-zoho-id"}
                value={inputValues.zohoId}
            />
            <Button
                submit
                label={buttonLabel}
                onClick={handleSubmit}
                loading={loading}
                className={styles.button}
            />
        </BaseForm>
    );
};