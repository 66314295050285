import React, { Fragment, useState } from "react";

import { useDialog, DialogTypes } from "@hooks/use-dialog";
import { useDealerManagementContext } from "./context";

import { IDealerMenuProps } from "@admin/types";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { makeStyles } from "@material-ui/core/styles";

import { Typography } from "~/components/Typography";
import { navigate } from "@utils/navigate";

const useStyles = makeStyles(() => ({
    button: {
        padding: 0,
    },
    menu: {
        borderRadius: 8,
        padding: 0,
    },
}));


export const DealerMenu: React.FC<IDealerMenuProps> = ({
    company,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const { showDialog, dialog } = useDialog();
    const { revokeAccess, readOnly, showCloneCompanyModal, showUpdateCompanyModal } = useDealerManagementContext();
    const styles = useStyles();

    const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (
        cb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
    ) => (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        e.preventDefault();
        if (cb) cb(e);
        setAnchorEl(null);
    };

    const doCloneCompany = () => {
        showCloneCompanyModal({
            action: `Clone ${company?.name}`,
            id: company?.id,
            values: {
                company,
            },
        });
    };

    const doUpdateCompany = () => {
        showUpdateCompanyModal({
            action: `Update ${company?.name}`,
            id: company?.id,
        });
    };

    return (
        <Fragment>
            <IconButton
                style={{
                    visibility: readOnly ? "hidden" : undefined,
                }}
                className={styles.button}
                onClick={handleMenuClick}
            >
                <MoreHorizIcon />
            </IconButton>

            <Menu
                keepMounted
                classes={{
                    paper: styles.menu,
                }}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose()}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem
                    onClick={doUpdateCompany}
                >
                    <Typography variant="body1">
                        Edit
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => navigate(`${company.id}/ctas/`)}
                >
                    <Typography variant="body1">
                        {"Manage CTAs"}
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={doCloneCompany}
                >
                    <Typography variant="body1">
                        Clone Company
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={handleClose((e) => {
                        showDialog({
                            title: "Revoke Access",
                            message: `Are you sure you want to revoke access for ${company.name}`,
                            type: DialogTypes.ContinueCancel,
                            action: () => {
                                revokeAccess(true, company.id);
                            },
                        });
                    })}
                >
                    <Typography variant="body1" color="error">
                        Archive Company
                    </Typography>
                </MenuItem>
            </Menu>
            {dialog}
        </Fragment>
    );
};

