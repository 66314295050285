import React from "react";

import Pagination from "@material-ui/lab/Pagination";
export interface INumericPagerProps {
    numeric: true;
    count: number;
    current: number;
    onChange: (page: number) => void
}

export type NumericPagerComponent = React.FC<Omit<INumericPagerProps, "numeric">>;
export const NumericPager: NumericPagerComponent = ({ count, current, onChange }) => {

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        onChange(value);
    };

    return (
        <Pagination
            count={count}
            page={current}
            onChange={handleChange}
            color="secondary"
            size="large"
        />
    );
};