import React, { Fragment, useRef } from "react";
import { navigate } from "@utils/navigate";
import { withStyles } from "@material-ui/core/styles";
import MuiButton, { ButtonProps } from "@material-ui/core/Button";

interface IButtonProps extends ButtonProps {
    visible?: boolean;
}
const ButtonWrapper: React.FC<IButtonProps> = ({ visible, ...props }) => {
    return (
        <MuiButton {...props} />
    );
};
const Button = withStyles<"root" | "text", any, IButtonProps>((theme) => ({
    root: {
        border: "1px solid",
        borderColor: theme.palette.border.dark,
        background: theme.palette.background.paper,
        visibility: ({ visible = true }) => visible ? "visible" : "hidden",
        minWidth: 100,
    },
    text: {
        ...theme.typography.inputLabel,
        textTransform: "none",
    },
}))(ButtonWrapper);

export interface INextPrevPagerProps {
    nextPrev: true;
    hasPrev?: boolean;
    nextKey?: string;
}

export type NextPrevPagerComponent = React.FC<Omit<INextPrevPagerProps, "nextPrev">>;
export const NextPrevPager: NextPrevPagerComponent = ({ nextKey, hasPrev }) => {
    const prevKey = useRef(nextKey || {});

    const handlePrev = (): void => {
        if (hasPrev) {
            navigate(-1, {
                backPathFallback: "/leads",
                back: true,
            });
        }
    };

    const handleNext = (): void => {
        if (nextKey) {
            navigate({
                query: {
                    next: nextKey,
                },
            }, {
                savePath: true,
                keepQuery: true,
            });
        }
    };

    return (
        <Fragment>
            <Button onClick={handlePrev} visible={hasPrev}>
                {"Prev"}
            </Button>
            <Button onClick={handleNext} visible={!!nextKey}>
                {"Next"}
            </Button>
        </Fragment>
    );
};
