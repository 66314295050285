import { NextPrevPager } from "./NextPager";
import { NumericPager } from "./NumericPager";
import type { INextPrevPagerProps, NextPrevPagerComponent } from "./NextPager";
import type { INumericPagerProps, NumericPagerComponent } from "./NumericPager";

export type PagerProps = INextPrevPagerProps | INumericPagerProps;

type PagerComponent<T extends PagerProps> = T extends INextPrevPagerProps
    ? NextPrevPagerComponent
    : NumericPagerComponent;

export const getComponent = <T extends PagerProps>(props: T): void | PagerComponent<T> => {
    if ((props as INextPrevPagerProps).nextPrev) {
        return NextPrevPager as PagerComponent<T>;
    }
    if ((props as INumericPagerProps).numeric) {
        return NumericPager as PagerComponent<T>;
    }
};