import React, {
    useEffect,
    useCallback,
    useState,
    useMemo,
    useRef,
    useContext,
    createContext,
} from "react";
import debounce from "lodash/debounce";

import { useQuery } from "@apollo/client";

import { WindowLocation } from "~/model/view/base/types";
import { ITableRow } from "~/components/Table";

import type { ActivityPage, CloneCompanyProps, CreateCompanyProps, DealerManagementQueryParams, EditProductsCompanyProps, ICloneCompanyVariables, ICreateCompanyVariables, IUpdateCompanyVariables, UpdateCompanyProps } from "@admin/types";

import { useAuth } from "@api/auth";
import { useCompanyList, QueriedCompany, useCompanyAccess, useCreateCompany, useUpdateCompanyInformation, useCloneCompany } from "@api/companies";
import { GetProducts } from "@api/query/products";

import { parseQuery } from "@utils/url";
import type { IComponentNavigateFn } from "@utils/navigate";
import { GetProductsQuery, GetProductsQueryVariables } from "@api/graphql/types";

import { generateNewViewContext } from "~/state/view";

import { CompanyForm } from "./forms/CompanyForm";

const [ViewContextProvider, useViewContext] = generateNewViewContext("DealerManagementView");
export { ViewContextProvider, useViewContext };

const PAGE_LIMIT = 20;

type DealerManagementValues = Pick<
    DealerManagementContextType,
    "rows" | "page"
>;

type DealerManagementContextType = {
    rows: ITableRow[];
    page: ActivityPage;
    search: string;
    loading: boolean;
    error: string;
    getAllProducts: any;
    errorMessage: string;
    companyId: number;
    selectedCompany: any;
    readOnly: boolean;
    revokeAccess: (archive: boolean, id: number) => void;
    setSearch: (search: string) => void;
    changePage: (position: number) => void;
    showCreateCompanyModal: (props: CreateCompanyProps) => void;
    toggleTool: (enabled: boolean, companyId: number, productId: number, equifaxId: string, securityCode: string) => void;
    setCompanyId: (id: number) => void;
    showCloneCompanyModal: (props: CloneCompanyProps) => void;
    showUpdateCompanyModal: (props: UpdateCompanyProps) => void;
};

const defaultValues: DealerManagementValues = {
    rows: [],
    page: { current: 1, count: 1 },
};

const defaultContext: DealerManagementContextType = {
    ...defaultValues,
    search: "",
    loading: true,
    error: "",
    readOnly: false,
    getAllProducts: [],
    errorMessage: "",
    companyId: 0,
    setSearch: (search: string) => { /* dummy Fn */ },
    changePage: (page: number) => { /* dummy Fn */ },
    toggleTool: () => undefined,
    revokeAccess: (archive: boolean, id: number) => alert(`Revoke Access: ${id}`),
    selectedCompany: (id: number) => console.log(id),
    showCreateCompanyModal: (props) => console.log(props),
    setCompanyId: (id: number) => { /* dummy Fn */ },
    showCloneCompanyModal: (props) => { /* dummy Fn */ },
    showUpdateCompanyModal: (props) => { /* dummy Fn */ },
};


const DealerManagementContext =
    createContext<DealerManagementContextType>(defaultContext);

export const useDealerManagementContext = () => {
    const context = useContext(DealerManagementContext);
    if (!context) {
        throw new Error(
            "You cannot use DealerManagementContext from outside of its Provider",
        );
    }

    return context;
};

const useDealerManagementContextValue = (navigate: IComponentNavigateFn, location: WindowLocation) => {
    const [values, setValues] = useState<DealerManagementValues>(defaultValues);
    const { selectedCompany } = useAuth();
    const { archiveCompany } = useCompanyAccess();
    const lastCompany = useRef(selectedCompany);

    const [companyId, setCompanyId] = useState(0);

    const { data: getAllProducts } = useQuery<
    GetProductsQuery,
    GetProductsQueryVariables
    >(GetProducts, {
        onError(err) {
            console.error(err);
        }});

    const {
        actions: {
            openModal: showLayoutModal,
            closeModal: closeLayoutModal,
        },
    } = useViewContext();

    const {
        page,
        search,
    } = useMemo<DealerManagementQueryParams>(() => {
        const query = parseQuery(location.search);
        const search = `${query.search || ""}`;
        const page = parseInt(`${query.page || 1}`);

        return {
            page: isNaN(page) ? 1 : page,
            search,
        };
    }, [location.search]);

    const { updateCompany } = useUpdateCompanyInformation();

    const showUpdateCompanyModal = useCallback((props) => {
        setCompanyId(props.id);
        const onSubmit = (props: IUpdateCompanyVariables) => {
            updateCompany(props);
            closeLayoutModal();
        };
        const updateCompanyVariables = {
            companyId: props?.id,
        };
        showLayoutModal({
            content: (
                <CompanyForm {...props} buttonLabel="SAVE" formType="update" variables={updateCompanyVariables} onSubmit={onSubmit} onComplete={closeLayoutModal} />
            ),
            props: {
                title: props.action,
            },
        });
    }, [showLayoutModal, closeLayoutModal, updateCompany]);

    const { createCompany } = useCreateCompany();

    const showCreateCompanyModal = useCallback((props) => {
        const onSubmit = (props: ICreateCompanyVariables) => {
            createCompany(props);
            closeLayoutModal();
        };
        showLayoutModal({
            content: (
                <CompanyForm {...props} buttonLabel="Add Dealer" formType="create" onSubmit={onSubmit} onComplete={closeLayoutModal} />
            ),
            props: {
                title: props.action,
            },
        });
    }, [showLayoutModal, closeLayoutModal, createCompany]);

    const { cloneCompany } = useCloneCompany();

    const showCloneCompanyModal = useCallback((props) => {
        setCompanyId(props.id);
        const groupDetails = props?.values?.company?.companyGroups?.nodes?.[0]?.group;
        const companyProducts = props?.values?.company?.companyProducts?.nodes?.map((cp: any) => cp?.product?.name);
        const cloneCompanyVariables = {
            companyId: props?.values?.company?.id,
            products: companyProducts && [...companyProducts],
            distributionType: groupDetails?.distributionType,
            groupType: groupDetails?.type,
        };
        const onSubmit = (props: ICloneCompanyVariables) => {
            cloneCompany(props);
            closeLayoutModal();
        };
        showLayoutModal({
            content: (
                <CompanyForm {...props} variables={cloneCompanyVariables} buttonLabel="SAVE" formType="clone" onSubmit={onSubmit} onComplete={closeLayoutModal} />
            ),
            props: {
                title: props.action,
            },
        });
    }, [showLayoutModal, closeLayoutModal, cloneCompany]);

    const { data, loading, error } = useCompanyList({
        search,
        page: page - 1,
        limit: PAGE_LIMIT,
    });

    useEffect(() => {
        if (selectedCompany !== lastCompany.current) {
            lastCompany.current = selectedCompany;
            navigate("?", {
                keepQuery: true,
                removeQuery: ["page"],
            });
        }
    }, [selectedCompany, navigate]);

    useEffect(() => {
        // avoid flicker
        if (loading) {
            return;
        }

        let mounted = true;
        const rows: ITableRow[] = (
            data?.companies?.nodes.map((node) => {
                return {
                    id: `${node.id}`,
                    values: [
                        node.name,
                        getDealerGroupName(node),
                        !node.archived ? "Active" : "Disabled",
                        node,
                        node,
                    ],
                } as ITableRow;
            }) || []
        );

        const count = Math.ceil(
            (data?.companies?.totalCount || PAGE_LIMIT) / PAGE_LIMIT,
        );

        mounted &&
            setValues({
                rows,
                page: {
                    current: page,
                    count,
                },
            });

        return () => {
            mounted = false;
        };
    }, [loading, data, page]);

    const changePage = useCallback(
        (page: number) => {
            navigate({
                query: page > 1 ? { page } : undefined,
            }, {
                keepQuery: true,
                removeQuery: page === 1 ? ["page"] : undefined,
            });
        },
        [navigate],
    );

    const doSetSearch = useCallback(
        (search?: string) => {
            navigate({
                query: search ? { search } : undefined,
            }, {
                keepQuery: true,
                removeQuery: ["page"].concat(!search ? ["search"] : []),
            });
        },
        [navigate],
    );

    const setSearch = useMemo(() => (
        debounce(doSetSearch, 500)
    ), [doSetSearch]);

    return {
        ...defaultContext,
        ...values,
        loading,
        errorMessage: "",
        error: error?.message || "",
        search,
        setSearch,
        getAllProducts,
        companyId,
        changePage,
        revokeAccess: (archive: boolean, id: number) => archiveCompany(archive, id),
        setCompanyId,
        showCreateCompanyModal,
        showCloneCompanyModal,
        showUpdateCompanyModal,
    };
};


const getDealerGroupName = (company: QueriedCompany) => {
    const supergroup = company.companyGroups.nodes?.find(
        (cg) => cg.group?.type === "SUPER",
    );
    return supergroup?.group?.name || company.groupByPrimaryGroup?.name;
};
export interface IDealerManagementProviderProps {
    navigate: IComponentNavigateFn;
    location: WindowLocation;
}

export const DealerManagementProvider: React.FC<IDealerManagementProviderProps> = ({ navigate, location, children }) => {
    const contextValue = useDealerManagementContextValue(navigate, location);

    return (
        <DealerManagementContext.Provider value={contextValue}>
            {children}
        </DealerManagementContext.Provider>
    );
};




