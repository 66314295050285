import React from "react";

import { navigate } from "@utils/navigate";

import Edit from "~/assets/vectors/edit-2.svg";

import { IconButton } from "@material-ui/core";

import { DealerMenu } from "./DealerMenu";

import { ITableColumn } from "~/components/Table";

import type { IEditButtonProps, CompanyProduct } from "@admin/types";

import { ActiveProduct, Products } from "~/components/Products";

const EditButton: React.FC<IEditButtonProps> = ({ products, company }) => {

    return (
        <IconButton size="small"
            onClick={() => navigate(`/admin/dealer-management/${company.id}/products/`)}
        >
            <Edit style={{ height: "16px" }} />
        </IconButton>
    );
};

export const userColumns: ITableColumn[] = [
    {
        label: "Dealership",
        property: "name",
    },
    {
        label: "Dealer Group",
        property: "primaryGroup",
    },
    {
        label: "Status",
        property: "status",
    },
    {
        label: "Product Access",
        property: "status",
        cell(props) {
            const cps: CompanyProduct[] = props.companyProducts.nodes.map((cp: { product: any; }) => cp.product);
            const products: ActiveProduct[] = cps.map((cp) => ({
                product: cp.name,
                active: cp.enabled,
            }));

            return (
                <div
                    style={{
                        display: "flex",
                        flexFlow: "row nowrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Products products={products} showDisabled />
                    <EditButton company={props} products={cps} />
                </div>
            );
        },
    },
    {
        label: "",
        property: "more",
        cell(props: any) {
            return (
                <DealerMenu company={props} />
            );
        },
    },
];