import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { getComponent } from "./pagers";

import type { PagerProps } from "./pagers";

export type { PagerProps };

const useStyles = makeStyles({
    root: {
        display: "grid",
        gridAutoFlow: "column",
        gridColumnGap: 10,
        padding: 10,
    },
});

export const Pager: React.FC<PagerProps> = (props) => {
    const styles = useStyles();
    const Component = getComponent(props);

    if (!Component) {
        throw new Error("Invalid props were defined for the Pager component.");
    }

    return (
        <Box className={styles.root}>
            <Component {...props as any} />
        </Box>
    );
};